import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import colors from "../styles/colors"
import BaseContainer from "./Container"
import Title from "./ContentBlockTitle"

const Wrapper = styled.section`
  position: relative;
  background: ${colors.white};

  &:nth-child(2n) {
    background: ${colors.gray.lightest};
  }
`

const Container = styled(BaseContainer)`
  z-index: 1;
  position: relative;
`

const ContentBlock = ({ children, title, tagline, background, ...props }) => (
  <Wrapper {...props}>
    <Container>
      {title && <Title tagline={tagline}>{title}</Title>}
      {children}
    </Container>
  </Wrapper>
)

ContentBlock.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.node,
  tagline: PropTypes.node,
}

ContentBlock.defaultProps = {
  backgroundColor: "transparent",
  background: [],
}

export default ContentBlock
