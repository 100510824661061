import styled from "styled-components"
import colors from "@styles/colors"
import React, { useState } from "react"
import Box from "@components/Box"
import Slider from "@components/Slider"

const PRICE = 19

const Divider = styled.hr`
  height: 1px;
  border: 0;
  background-color: ${colors.gray.normal};
`

const PricingCalculator = () => {
  const [hours, setHours] = useState(5)
  const [salary, setSalary] = useState(30)

  function handleHoursSpentChange(event) {
    setHours(Number(event.currentTarget.value))
  }

  function handleSalaryChange(event) {
    setSalary(Number(event.currentTarget.value))
  }

  const hoursSaved = Math.round(hours / 2)
  const savings = hoursSaved * salary - PRICE

  return (
    <Box margin={"0 auto"} width={[1, 1 / 2]}>
      <Slider
        label={"Time spent writing change logs by hand:"}
        onChange={handleHoursSpentChange}
        value={hours}
        suffix={"hours per month"}
        min={5}
        max={50}
      />
      <Divider />
      <Slider
        label={"Salary:"}
        onChange={handleSalaryChange}
        value={salary}
        prefix={"€"}
        suffix={"per hour"}
        min={30}
        max={100}
      />
      <Divider />
      <Box padding={"20px 0"} textAlign={"center"}>
        <Box as={"span"} fontSize={"1.3em"}>
          {hoursSaved} hours or € {savings} saved per month
        </Box>
      </Box>
    </Box>
  )
}

PricingCalculator.propTypes = {}

export default PricingCalculator
