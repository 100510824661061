import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/seo"
import HomePage from "../components/HomePage/HomePage"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomePage />
  </Layout>
)

export default IndexPage
