import styled from "styled-components"
import Color from "@styles/colors"

export default styled.span`
  padding: 0.2rem 1rem;
  background: ${Color.black};
  color: ${Color.primary.normal};

  &:hover {
    background: ${Color.primary.normal};
    color: ${Color.gray.darkest};
  }
`
