import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Box from "./Box"
import colors from "../styles/colors"
import Paragraph from "./Paragraph"
import { FaQuoteLeft } from "react-icons/fa"

const Container = styled(Box)`
  padding: 30px 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.normal};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  position: relative;

  &:hover {
    border-color: ${colors.primary.normal};
  }
`

const Text = styled(Paragraph)``

const Author = styled(Box)`
  width: 100%;
  font-style: italic;
  color: ${colors.gray.dark};

  a {
    color: ${colors.gray.dark};
    font-weight: normal;
  }
`

const Icon = styled.div`
  position: absolute;
  top: -20px;
  right: calc(50% - 20px);

  border-radius: 50%;
  background-color: ${colors.primary.normal};
  background-position: center;
  padding: 8px 8px;
`

const Testimonial = ({ content, role, company }) => (
  <Container>
    <Icon>
      <FaQuoteLeft />
    </Icon>
    <Text>"{content}"</Text>
    <Author>
      {role} - {company}
    </Author>
  </Container>
)

Testimonial.propTypes = {
  content: PropTypes.node.isRequired,
  role: PropTypes.node.isRequired,
  company: PropTypes.node.isRequired,
}

export default Testimonial
