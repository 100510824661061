import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Colors from "@styles/colors"
import Box from "./Box"
import Flex from "./Flex"
import Highlight from "./Highlight"
import Heading3 from "./Headings/Heading3"

const Container = styled(Box)`
  padding: 20px 40px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.gray.normal};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
`

const FeatureList = styled(Box)`
  list-style: none;
  padding: 20px 0;
  line-height: 2em;

  strike {
    opacity: 0.6;
  }
`

const PricingText = styled(Heading3)`
  font-weight: 500;
  font-size: 2.6rem;
`

const Divider = styled.hr`
  height: 1px;
  border: 0;
  background-color: ${Colors.gray.normal};
`

const Name = styled(Heading3)`
  margin: 0 auto 20px;
`

const Pricing = ({
  name,
  amount,
  features,
  summary,
  description,
  ...props
}) => (
  <Container {...props}>
    <Name>
      <Highlight>{name}</Highlight>
    </Name>
    <Flex flexDirection={"column"} width={"100%"}>
      <PricingText>
        {amount === 0 ? (
          <Box as={"span"}>Free</Box>
        ) : (
          <Box>
            <Box as={"span"} color={Colors.gray.dark} fontSize={"0.5em"}>
              €
            </Box>
            <Box as={"span"} fontSize={"1em"}>
              {amount}
            </Box>{" "}
            <Box as={"span"} fontSize={"0.5em"} color={Colors.gray.dark}>
              Per Month
            </Box>
          </Box>
        )}
      </PricingText>
      <Divider />
      <FeatureList as={"ul"}>
        {features.map((feature, index) => (
          <Box as={"li"} key={index}>
            {feature}
          </Box>
        ))}
      </FeatureList>
    </Flex>
  </Container>
)

Pricing.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
}

Pricing.defaultProps = {
  features: [],
}

export default Pricing
