import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Colors from "@styles/colors"
import Heading2 from "./Headings/Heading2"

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`

const Title = styled(Heading2)`
  margin-bottom: 0.9rem;
`

const Tagline = styled.p`
  color: ${Colors.gray.dark};
  margin-bottom: 0.9rem;
`

const ContentBlockTitle = ({ children, tagline }) => (
  <Wrapper>
    <Title>{children}</Title>
    {tagline && <Tagline>{tagline}</Tagline>}
  </Wrapper>
)

ContentBlockTitle.propTypes = {
  children: PropTypes.node,
  tagline: PropTypes.node,
}

export default ContentBlockTitle
