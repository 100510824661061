import React from "react"
import { Tiles } from "@rebass/layout"
import { Flex } from "reflexbox"
import ContentBlock from "@components/ContentBlock"
import Box from "@components/Box"
import Colors from "@styles/colors"
import mediaQueries from "@styles/mediaQueries"
import Heading3 from "@components/Headings/Heading3"
import Heading1 from "@components/Headings/Heading1"
import Paragraph from "@components/Paragraph"
import Link from "@components/Link"
import Pricing from "@components/Pricing"
import styled from "styled-components"
import {
  FaSearch,
  FaChartLine,
  FaConnectdevelop,
  FaUsers,
  FaRegListAlt,
  FaFileAlt,
} from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import Image, { Thumbnail } from "../Image"
import Highlight from "../Highlight"
import Button from "../Button"
import PricingCalculator from "./PricingCalculator"
import Testimonial from "../Testimonial"

const Hero = styled.section`
  position: relative;
  padding: calc(1rem + 4vw) 0;
  text-align: center;
  z-index: 1;
  
  &:before {
    background: url("${(props) => props.backgroundImage}") no-repeat top center;
    content: ""; 
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
    
    @media (max-width: ${mediaQueries.lg}) {
    background-size: 80%;
    } 
  }

  & figure {
    margin: 0 auto;
    max-width: 971px;
  }

  @media (max-width: ${mediaQueries.lg}) {
    padding: 10px 30px;
  }
`

const HeroText = styled(Paragraph)`
  font-size 1.4em;
  line-height: 1.6em;
  margin-bottom: 0;
`

const HeroTitle = styled(Heading1)`
  font-size: 4em;
  line-height: 1.8em;

  @media (max-width: ${mediaQueries.lg}) {
    font-size: 1.8em;
    line-height: 1.4em;
    margin-bottom: 40px;
  }
`

const Step = styled(Box)`
  border-bottom: 1px solid ${Colors.gray.normal};

  @media (min-width: ${mediaQueries.lg}) {
    border-bottom: none;
  }
`

const Disclaimer = styled(Paragraph)`
  font-size: 0.85em;
  margin-bottom: 0;
  color: ${Colors.gray.dark};
`

const Benefit = styled(Flex)`
  min-height: 150px;
  padding: 40px 20px;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border: 1px solid ${Colors.gray.normal};
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;

  @media (min-width: ${mediaQueries.lg}) {
    border-right-style: solid;

    &:nth-child(3n) {
      border-right-style: none;
    }

    &:nth-last-child(-n + 3) {
      border-bottom-style: none;
    }
  }
`

const BenefitIcon = styled(Box)`
  font-size: 2em;
`

const BenefitTitle = styled(Heading3)`
  font-weight: 400;
  font-size: 1.1em;
  margin-bottom: 0;
  line-height: 1.6em;
  text-align: center;
`

const query = graphql`
  query {
    site {
      siteMetadata {
        urls {
          signup
        }
      }
    }
    heroBackground: file(relativePath: { eq: "main-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 704) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 971) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureAlwaysUpToDate: file(
      relativePath: { eq: "product/up-to-date.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureKeepAChangelog: file(
      relativePath: { eq: "product/keepachangelog.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureIntegrations: file(
      relativePath: { eq: "product/integrations.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default () => {
  const {
    featureAlwaysUpToDate,
    featureKeepAChangelog,
    featureIntegrations,
    hero,
    heroBackground,
    site,
  } = useStaticQuery(query)

  return (
    <>
      <Hero backgroundImage={heroBackground.childImageSharp.fluid.src}>
        <HeroTitle>
          <Highlight>Communicating</Highlight> about change is hard. <br />{" "}
          We're making it <Highlight>easy</Highlight>
        </HeroTitle>
        <Box mt={64} mb={20} as={"figure"}>
          <Image
            fluid={hero.childImageSharp.fluid}
            loading={"eager"}
            fadeIn={false}
          />
        </Box>
        <Box m={"20px auto 0"} width={["100%", "75%", "50%"]}>
          <HeroText>
            ChangeHub delivers information you need <em>without</em>{" "}
            interrupting developers. We make this happen by integrating directly
            with your codebase and turning your repository into a living
            changelog.
          </HeroText>
        </Box>
      </Hero>
      <ContentBlock title={"Developers and PMs love it"}>
        <Tiles columns={[1, 2]}>
          <Testimonial
            content={
              "It has helped us to improve communication across our teams. Thanks to ChangeHub, we can continue to release fast and still keep everyone up to date."
            }
            role={"Software Engineer"}
            company={
              <Link external to={"https://flexmail.eu/"}>
                Flexmail
              </Link>
            }
          />
          <Testimonial
            content={
              "Because of ChangeHub I'm no longer chasing developers for status updates and can focus more on communicating with our customers"
            }
            role={"Product Manager"}
            company={
              <Link external to={"https://invoxapp.com/"}>
                Invox
              </Link>
            }
          />
        </Tiles>
      </ContentBlock>
      <ContentBlock title={"Key benefits"}>
        <Tiles columns={[1, 3]} gap={"0"} mb={"10px"}>
          <Benefit>
            <BenefitIcon>
              <FaSearch />
            </BenefitIcon>
            <BenefitTitle>
              Find the information you need without having to ask for it
            </BenefitTitle>
          </Benefit>
          <Benefit>
            <BenefitIcon>
              <FaChartLine />
            </BenefitIcon>
            <BenefitTitle>
              Easily understand which changes impact customers the most
            </BenefitTitle>
          </Benefit>
          <Benefit>
            <BenefitIcon>
              <FaRegListAlt />
            </BenefitIcon>
            <BenefitTitle>
              Discover the difference between what was <em>supposed</em> to be
              implemented and what was actually implemented
            </BenefitTitle>
          </Benefit>
          <Benefit>
            <BenefitIcon>
              <FaFileAlt />
            </BenefitIcon>
            <BenefitTitle>
              Share updates with the rest of your team in 2 click
            </BenefitTitle>
          </Benefit>
          <Benefit>
            <BenefitIcon>
              <FaConnectdevelop />
            </BenefitIcon>
            <BenefitTitle>
              Integrate with multiple version control providers*
            </BenefitTitle>
          </Benefit>
          <Benefit>
            <BenefitIcon>
              <FaUsers />
            </BenefitIcon>
            <BenefitTitle>Use ChangeHub with teams of 5 or more*</BenefitTitle>
          </Benefit>
        </Tiles>
        <Box textAlign={"center"}>
          <Disclaimer>* Paid plans only</Disclaimer>
        </Box>
      </ContentBlock>
      <ContentBlock title={"It's easy to get started"}>
        <Tiles columns={[1, 3]} gap={"40px"}>
          <Step>
            <Thumbnail fluid={featureIntegrations.childImageSharp.fluid} />
            <Heading3>1. Connect your repository</Heading3>
            <Paragraph>
              GitHub? Bitbucket?{" "}
              <Link to={"/docs/integrations"} external>
                We've got you covered
              </Link>
              . It's as easy as 3 clicks to connect your repository of choice.
            </Paragraph>
          </Step>
          <Step>
            <Thumbnail fluid={featureKeepAChangelog.childImageSharp.fluid} />
            <Heading3>2. Create pull requests</Heading3>
            <Paragraph>
              Next, keep doing what you do best! Ship pull requests formatted in{" "}
              <Link to={"https://keepachangelog.com/en/1.0.0/"} external>
                Keep a Changelog
              </Link>{" "}
              format.
            </Paragraph>
          </Step>
          <Step>
            <Thumbnail fluid={featureAlwaysUpToDate.childImageSharp.fluid} />
            <Heading3>3. Always up-to-date</Heading3>
            <Paragraph>
              ChangeHub keeps track of the details for you and updates
              automatically based on merges and tags
            </Paragraph>
          </Step>
        </Tiles>
      </ContentBlock>
      <ContentBlock title={"How much will I save?"}>
        <PricingCalculator />
      </ContentBlock>
      <ContentBlock title={"Pricing"}>
        <Tiles columns={[1, 2]} gap={"20px"}>
          <Pricing
            name={"Personal"}
            description={
              "Helping freelancers and hobby projects maintain a change log without any effort."
            }
            amount={0}
            features={[
              "Share-able change log",
              "GitHub integration",
              "Up to 3 tracked repositories",
            ]}
          />
          <Pricing
            name={"Startup"}
            description={
              "Aimed at startups that are working on their first product."
            }
            amount={19}
            features={[
              "Share-able change log",
              "GitHub integration",
              "Bitbucket Server integration",
              "GitLab integration (beta)",
              "Unlimited tracked repositories",
              "Unlimited team members",
            ]}
          />
        </Tiles>
        <Box textAlign={"center"} m={"50px"}>
          <Button
            to={site.siteMetadata.urls.signup}
            external
            size={"lg"}
            type={"primary"}
          >
            Connect my first repository
          </Button>
        </Box>
      </ContentBlock>
    </>
  )
}
