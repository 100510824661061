import React from "react"
import styled from "styled-components"
import { Flex } from "reflexbox"
import colors from "@styles/colors"
import { Slider as BaseSlider } from "@rebass/forms/styled-components"
import Box from "@components/Box"
import Heading3 from "./Headings/Heading3"

const Container = styled(Box)`
  padding: 20px 0;
`

const Label = styled(Heading3)`
  font-weight: normal;
`

const StyledSlider = styled(BaseSlider)`
  height: 8px;
  background-color: ${colors.gray.normal};
  color: ${colors.primary.normal};
`

const Slider = ({ label, value, onChange, prefix, suffix, ...otherProps }) => (
  <Container mb={"20px"}>
    <Label>{label}</Label>
    <Flex alignItems={"center"}>
      <Box marginRight={"50px"} width={[1, 1 / 2]}>
        <StyledSlider
          onChange={onChange}
          defaultValue={value}
          {...otherProps}
        />
      </Box>
      <Box as={"span"} fontSize={"1.6em"} fontWeight={700}>
        {prefix} {value} {suffix}
      </Box>
    </Flex>
  </Container>
)

Slider.propTypes = {}

export default Slider
